$primary-color: #5A67D8;
$success-color: #48BB78;
$danger-color: #ff4d4d;
$background-color: #F7FAFC;
$card-bg: #ffffff;
$input-border: #ddd;
$text-color: #333;

.contact-form-container {
  padding: 20px;
  background-color: $background-color;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.back-button {
  align-self: flex-start;
  background-color: lighten($primary-color, 30%);
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s;
  &:hover {
    background-color: lighten($primary-color, 20%);
  }
}

.contact-form {
  max-width: 500px;
  width: 100%;
  padding: 20px;
  background-color: $card-bg;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: $primary-color;
  }
  label {
    display: block;
    font-weight: bold;
    color: $text-color;
    margin-bottom: 5px;
  }
  input[type="text"],
  select {
    width: 100%;
    padding: 10px;
    border: 1px solid $input-border;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .phone-number-group {
    display: flex;
    align-items: flex-start;
    background-color: lighten($card-bg, 5%);
    padding: 10px;
    border-radius: 6px;
    margin-bottom: 15px;
    position: relative;
    border: 1px solid $input-border;
    label {
      flex: 1;
      margin: 0;
      color: $text-color;
    }
    select,
    input[type="text"] {
      flex: 2;
      margin-right: 10px;
    }
    .primary-checkbox {
      display: flex;
      align-items: center;
      margin-top: 4px;
      input {
        margin-right: 5px;
      }
    }
    .delete-button {
      background: none;
      border: none;
      color: $danger-color;
      cursor: pointer;
      font-size: 18px;
      position: absolute;
      right: 10px;
      top: 10px;
      transition: color 0.3s;
      &:hover {
        color: darken($danger-color, 10%);
      }
    }
  }
  .add-phone-button {
    display: block;
    width: 40px;
    height: 40px;
    background-color: $success-color;
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 24px;
    cursor: pointer;
    margin: 20px auto;
    transition: background-color 0.3s;
    &:hover {
      background-color: darken($success-color, 10%);
    }
  }
  .save-contact-button {
    display: block;
    width: 100%;
    padding: 12px;
    border: none;
    background-color: $primary-color;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
    transition: background-color 0.3s;
    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }
}
