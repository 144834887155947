$font-stack: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

body {
  margin: 0;
  font-family: $font-stack;
  background: #F7FAFC;
  color: #2D3748;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New', monospace;
}
