$primary-color: #4a90e2;
$background-color: #f9f9fb;
$icon-gray: #c5c5c5;
$light-gray: #e0e0e0;

body {
  font-family: "Arial", sans-serif;
  background-color: $background-color;
  margin: 0;
}

.elderly-dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  .back-button {
    align-self: flex-start;
    background-color: $primary-color;
    border: none;
    border-radius: 8px;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    cursor: pointer;
    margin-bottom: 20px;
    transition: background-color 0.3s, transform 0.2s;
    &:hover {
      background-color: darken($primary-color, 10%);
      transform: scale(1.05);
    }
  }

  .title {
    font-size: 28px;
    font-weight: 600;
    color: $primary-color;
    margin-bottom: 5px;
  }

  .subtitle {
    font-size: 18px;
    font-weight: 400;
    color: $icon-gray;
    margin-bottom: 20px;
  }

  .menu {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .menu-item {
      display: flex;
      align-items: center;
      padding: 15px 15px;
      border: 1px solid $light-gray;
      border-radius: 12px;
      background-color: white;
      cursor: pointer;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      transition: transform 0.2s, box-shadow 0.3s;
      position: relative;

      &:hover {
        transform: scale(1.02);
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
      }

      .icon {
        width: 32px;
        height: 32px;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 18px;
        &.error {
          background-color: #ff6b6b;
          color: white;
        }
        &.circle {
          border: 2px solid $icon-gray;
          background-color: white;
          color: $icon-gray;
        }
        &.update {
          background-color: #48bb78;
          color: white;
        }
      }

      .text {
        font-size: 16px;
        font-weight: 500;
        color: #333;
        flex-grow: 1;
      }

      .arrow {
        margin-left: auto;
        font-size: 18px;
        color: $icon-gray;
        transition: color 0.2s;
      }

      &:hover .arrow {
        color: $primary-color;
      }
    }
  }
}
