$primary-color: #5A67D8;
$background-color: #F7FAFC;
$card-bg: #ffffff;
$light-gray: #e0e0e0;
$text-color: #2D3748;

.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: $background-color;
  min-height: 100vh;
}

.settings-icon {
  align-self: flex-end;
  font-size: 24px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.1);
  }
}

.dashboard-title {
  font-size: 32px;
  margin-bottom: 20px;
  color: $primary-color;
}

.notifications-title,
.elderlies-title {
  font-size: 18px;
  margin-bottom: 10px;
}

.notifications,
.elderlies {
  width: 100%;
  max-width: 400px;
  .notification,
  .elderly {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $light-gray;
    padding: 10px 0;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
      background-color: lighten($primary-color, 40%);
    }
    .icon {
      font-size: 20px;
      margin-right: 10px;
      &.error {
        color: red;
      }
      &.success {
        color: green;
      }
    }
    .text {
      font-size: 16px;
      color: $text-color;
    }
  }
}
