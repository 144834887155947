$primary-color: #5A67D8;
$background-color: #F7FAFC;
$card-bg: #ffffff;
$light-gray: #e0e0e0;

.favorites-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 300px;
  margin: 0 auto;
  background-color: $background-color;
  position: relative;
}

.phone-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin-bottom: 20px;
  .grid-tile {
    width: 120px;
    height: 120px;
    background-color: $card-bg;
    border: 1px solid $light-gray;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: transform 0.3s, background-color 0.3s;
    &:hover {
      transform: scale(1.05);
      background-color: lighten($card-bg, 5%);
    }
    .plus-icon {
      font-size: 24px;
      color: $light-gray;
    }
  }
}

.dropdown {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $card-bg;
  border-top: 1px solid $light-gray;
  padding: 10px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid $light-gray;
    border-radius: 4px;
  }
  .contact-list {
    max-height: 200px;
    overflow-y: auto;
    margin-top: 10px;
    .contact-item {
      padding: 8px;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: background-color 0.3s;
      &:hover {
        background-color: lighten($primary-color, 40%);
      }
      .contact-icon {
        margin-right: 8px;
        font-size: 20px;
      }
    }
  }
}

.save-button {
  position: absolute;
  bottom: 20px;
  right: 10px;
  padding: 10px 20px;
  background-color: $primary-color;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: darken($primary-color, 10%);
  }
}
