$primary-color: #4a90e2;
$success-color: #48bb78;
$background-color: #f9f9fb;
$text-color: #333;

.update-app {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: $background-color;
  min-height: 100vh;

  .card {
    background-color: white;
    border-radius: 12px;
    padding: 30px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    text-align: center;
  }

  .update-icon {
    font-size: 48px;
    color: $success-color;
    margin-bottom: 10px;
  }

  .title {
    font-size: 24px;
    font-weight: 600;
    color: $primary-color;
    margin-bottom: 10px;
  }

  .message {
    font-size: 16px;
    color: $text-color;
    margin-bottom: 20px;
    line-height: 1.5;
  }

  .update-button {
    background-color: $success-color;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
    &:hover {
      background-color: darken($success-color, 10%);
      transform: scale(1.05);
    }
  }
}
