$primary-color: #5A67D8;
$background-color: #F7FAFC;
$card-bg: #ffffff;
$input-border: #ddd;
$text-color: #2D3748;

.settings-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: $card-bg;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.settings-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 26px;
  color: $primary-color;
}

.setting-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  label {
    font-weight: bold;
    color: $text-color;
    margin-right: 10px;
    flex: 1;
  }
  input[type="number"] {
    flex: 2;
    padding: 8px;
    border: 1px solid $input-border;
    border-radius: 4px;
    font-size: 14px;
  }
  input[type="checkbox"] {
    transform: scale(1.2);
    margin-left: 10px;
  }
}

.save-settings-button {
  display: block;
  width: 100%;
  padding: 12px;
  border: none;
  background-color: $primary-color;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
  transition: background-color 0.3s;
  &:hover {
    background-color: darken($primary-color, 10%);
  }
}
