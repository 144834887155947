$primary-color: #5A67D8;
$background-color: #F7FAFC;
$card-bg: #ffffff;
$light-gray: #e0e0e0;
$text-color: #2D3748;

.settings-container {
    max-width: 500px;
    margin: 20px auto;
    background-color: $card-bg;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.settings-item {
    padding: 16px;
    border-bottom: 1px solid $light-gray;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
        background-color: lighten($primary-color, 40%);
    }
}

.settings-item:last-child {
    border-bottom: none;
}

.icon {
    font-size: 20px;
    color: $primary-color;
}
